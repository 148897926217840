import React from "react";
import "./App.css"; // Ensure you import the CSS where you defined the animation
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const NavBar = () => {
  return (
    <div className="mt-6 flex flex-col-reverse md:flex-row justify-between items-center px-8 mb-8">
      <img
        className="w-92 h-40 mt-4 md:mt-0" // Adjust margin top for small screens
        src="Bull Stomping Rightward.svg"
        alt="Spinning logo"
      />
      {/* <span>img placeholder</span> */}
      <div className="flex flex-col items-center md:items-center gap-4 order-1 md:order-none">
        <h2 className="text-8xl md:text-8xl">Bullsy</h2>
        <h3 className="text-3xl">Guardian of the bull run</h3>
      </div>

      <div className="flex space-x-6 mt-4 md:mt-0 hidden md:flex">
        {/* Hidden on small screens and visible on medium and larger screens */}
        <a
          href="https://x.com/BullsyOnSol"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black"
        >
          <FaXTwitter className="w-8 h-8" />
        </a>
        <a
          href="https://t.me/bullsythebull"
          target="_blank"
          rel="noopener noreferrer"
          className="text-black"
        >
          <FaTelegramPlane className="w-8 h-8" />
        </a>
      </div>
    </div>
  );
};

export default NavBar;
