import React from "react";

const Buttons = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10 md:gap-16 justify-center mt-8 mb-16 font-semibold">
      <button className="text-3xl md:text-5xl hover:underline">
        <a
          target="_blank"
          href="https://pump.fun/2vXwxdJMHYKmp2mqjST839TdPLaF9b1kNsevWivkSeTt"
        >
          Buy $bullsy
        </a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank" href="">
          Dexscreener
        </a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank" href="">
          DEXTools
        </a>
      </button>
      <button className="text-3xl md:text-5xl hover:underline">
        <a target="_blank" href="https://x.com/BullsyOnSol">
          Bullsy on X
        </a>
      </button>
    </div>
  );
};

export default Buttons;
