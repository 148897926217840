
import './App.css';
import NavBar from './NavBar';
import CA from './CA';
import TopCarousel from './carousel/TopCarousel';
import About from './About';
import HowToBuy from './HowToBuy';
import BurnCount from './BurnCount';
import MemeGallery from './MemeGallery';
import Footer from './Footer';
import Buttons from './Buttons';

function App() {
  return (
    <div className="App font-comic">
      <NavBar />
      <img className="w-full" src="4.png" />
      <div className="flex flex-col mt-10">
        {/* <TopCarousel /> */}
        <Buttons />

        <CA />

        <About />
        <HowToBuy />
        <div className="flex flex-col bg-bullsy text-white">
          <h1 className="text-6xl mt-6">Roadmap:</h1>
          <h1 className="text-4xl mt-6">1.) Initiate Stampede</h1>
          <h1 className="text-4xl mt-6">2.) Trample the jeets</h1>
          <h1 className="text-4xl mt-6">3.) Bullions.</h1>

          <div class="flex flex-col md:flex-row items-center justify-center gap-10 mb-8 mt-8">
            <img
              class="w-96 md:w-128 h-96 md:h-128 rounded-lg object-contain"
              src="Jeet Cautious.png"
              alt="cautious"
            />

            <img
              class="w-80 md:w-96 h-80 md:h-96 rounded-lg object-cover"
              src="BlackBull.png"
              alt="bull"
            />
          </div>
        </div>
        <MemeGallery />
        <img src="Banner2.png" alt="" />
        <Footer />
      </div>
    </div>
  );
}

export default App;
