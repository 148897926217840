import React from "react";

const About = () => {
  return (
    <div className="px-6 md:px-40 flex flex-col md:flex-row p-10 gap-12 w-full justify-between mb-8 items-center">
      <div className="md:flex-1 text-center md:text-left">
        <h1 className="text-4xl md:text-5xl mb-4 ">Meet Bullsy</h1>
        <p className="text-lg md:text-xl">
          In the wild world of crypto, amidst the chaos of moonshots and rug
          pulls, there emerges a legend – Bullsy, the bull with more gains than
          horns. Born in the midst of a thousand pump-and-dumps, Bullsy embodies
          the spirit of hodling strong and riding high on every green candle.
          With a heart of diamond hands and a mind sharper than a bullish trend,
          Bullsy scoffs at the jeeters who sell at the first sight of a red
          candle. Picture this: while others panic, Bullsy charges headfirst
          into the dips, snorting at FUD like it's a bad joke. Legend has it
          that Bullsy's favorite pastime is stacking sats and tipping fedoras to
          the moon. Despite the jeeters' whispers of "sell low, buy high,"
          Bullsy stands firm, inspiring a cult following of diamond-handed
          hodlers who know the true power of bullish vibes.
        </p>
      </div>
      <img
        className="w-64 md:w-100 h-64 md:h-80 rounded-lg object-fit"
        src="bullsy-pic.jpg"
        alt="bullsy"
      />
    </div>
  );
};

export default About;
