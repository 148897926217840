import React from "react";

const HowToBuy = () => {
  return (
    <div className="relative text-bullsy bg-blue-800 h-auto flex items-start justify-center py-10">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(Banner1.png)`,
          filter: "brightness(0.6) contrast(1.1)",
        }}
      >
        <div className="absolute inset-0 bg-blue-800 opacity-30"></div>{" "}
        {/* Blue Overlay */}
      </div>

      {/* Content */}
      <div className="relative px-10 py-12 w-full max-w-6xl flex flex-col md:flex-row gap-10">
        {/* Left Section */}
        <div className="flex-1 flex flex-col items-center justify-start text-center md:text-left">
          <h2 className="text-6xl md:text-8xl  mb-6">How to Buy</h2>
        </div>

        {/* Right Section */}
        <div className="flex-1 pl-0 md:pl-10">
          <h2 className="text-3xl md:text-4xl mb-2">
            CREATE A WALLET
          </h2>
          <p className="mb-4 text-lg md:text-xl ">
            If you're using a web browser like Chrome or Firefox, download a
            wallet extension. If on a mobile device like an iPhone or Android,
            download your preferred wallet from your device's app store.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://phantom.app/download"
          >
            <button className="bg-phantom hover:bg-blue-300 text-black  py-2 px-4 rounded mb-6">
              Download Phantom
            </button>
          </a>

          <h2 className="text-3xl md:text-4xl  mt-6 mb-2">
            OBTAIN SOL
          </h2>
          <p className="mb-4 text-lg md:text-xl ">
            You need SOL to buy $bullsy. You can either buy it directly in your
            Phantom wallet, or acquire it on another exchange like Binance or
            Coinbase and send it to your wallet.
          </p>

          <h2 className="text-3xl md:text-4xl  mt-6 mb-2">
            GO TO RAYDIUM
          </h2>
          <p className="mb-4 text-lg md:text-xl ">
            Connect your wallet to the Raydium site. Paste the $bullsy token
            address (which can be found at the top of the site) into Raydium,
            select $bullsy, and confirm. When Raydium prompts you for a wallet
            signature, sign.
          </p>

          <h2 className="text-3xl md:text-4xl  mt-6 mb-2">
            SWAP FOR $BULLSY
          </h2>
          <p className="mb-4 text-lg md:text-xl">
            Swap your $SOL for $bullsy. Make sure your slippage is configured
            correctly!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
