import React, { useState } from "react";

const MemeGallery = () => {
  

  // Array of image URLs (replace with your actual image paths)
  const images = [
    "BlackBull.png",
    "Jeet Crowd.png",
    "bullsy-up.jpg",
    "BullColorCandleVersion.png",
    "Jeet Wrestling Bull_NoText.png",
  ];


  return (
    <div className="py-10 ">
      {/* Header Section */}
      <div className="text-center mb-10">
        <h2 className="text-6xl text-black">CONTENT</h2>
        <a
          href="https://x.com/BullsyOnSol" // Replace with the actual link to "X"
          target="_blank"
          rel="noopener noreferrer"
          className="text-bullsy text-xl mt-2 inline-block hover:underline"
        >
          See more on X
        </a>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {images.map((src, index) => (
            <div key={index} className="aspect-w-1 aspect-h-1">
              <img
                src={src}
                alt={`Meme ${index + 1}`}
                className="object-fit w-full h-full rounded-lg shadow-md"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MemeGallery;
